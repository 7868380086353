var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main contract-detail" },
    [
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-cell-group",
            [
              _c("van-cell", {
                attrs: {
                  title: "合同名称",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.contractForm.contractName
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "合同有效期",
                  value:
                    _vm.utils.dateFormat(_vm.contractForm.contractDateStart) +
                    "-" +
                    _vm.utils.dateFormat(_vm.contractForm.contractDateEnd)
                }
              }),
              _c("file-list", {
                attrs: { "file-list": _vm.fileList, title: "合同内容" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "send-email" },
        [
          _c(
            "van-button",
            {
              attrs: {
                block: "",
                square: "",
                type: "info",
                "native-type": "button"
              },
              on: {
                click: function($event) {
                  _vm.showSendEmailPopup = true
                }
              }
            },
            [_vm._v(" 发送电子邮件 ")]
          )
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.showSendEmailPopup,
            callback: function($$v) {
              _vm.showSendEmailPopup = $$v
            },
            expression: "showSendEmailPopup"
          }
        },
        [
          _c("van-nav-bar", { attrs: { title: "发送邮件" } }),
          _c("van-field", {
            attrs: {
              rows: "4",
              autosize: "",
              label: "收件人",
              type: "textarea",
              placeholder: "请输入邮箱地址,多个用逗号分隔"
            },
            model: {
              value: _vm.sendEmailForm.tos,
              callback: function($$v) {
                _vm.$set(_vm.sendEmailForm, "tos", $$v)
              },
              expression: "sendEmailForm.tos"
            }
          }),
          _c(
            "div",
            { staticClass: "button-area" },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    round: "",
                    plain: "",
                    type: "info",
                    "native-type": "button"
                  },
                  on: {
                    click: function($event) {
                      _vm.showSendEmailPopup = false
                    }
                  }
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "van-button",
                {
                  attrs: { round: "", type: "info", "native-type": "button" },
                  on: { click: _vm.sendEmail }
                },
                [_vm._v(" 确定 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }