<template>
  <div class="van-main contract-detail">
    <div class="van-common-detail">
      <van-cell-group>
        <van-cell title="合同名称" :value="utils.isEffectiveCommon(contractForm.contractName)" />
        <van-cell title="合同有效期" :value="utils.dateFormat(contractForm.contractDateStart) + '-' + utils.dateFormat(contractForm.contractDateEnd)" />
        <file-list :file-list="fileList" title="合同内容" />
      </van-cell-group>
    </div>
    <div class="send-email">
      <van-button block square type="info" native-type="button" @click="showSendEmailPopup=true">
        发送电子邮件
      </van-button>
    </div>
    <van-popup v-model="showSendEmailPopup" round position="bottom">
      <van-nav-bar
        title="发送邮件"
      />
      <van-field
        v-model="sendEmailForm.tos"
        rows="4"
        autosize
        label="收件人"
        type="textarea"
        placeholder="请输入邮箱地址,多个用逗号分隔"
      />
      <div class="button-area">
        <van-button round plain type="info" native-type="button" @click="showSendEmailPopup=false">
          取消
        </van-button>
        <van-button round type="info" native-type="button" @click="sendEmail">
          确定
        </van-button>
      </div>
    </van-popup>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, uploader, Field, Popup, NavBar } from 'vant'
import BackButton from '@/components/back-button'
import fileList from '@/components/file-list'
export default {
  components: {
    BackButton,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [uploader.name]: uploader,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [NavBar.name]: NavBar,
    fileList
  },
  data () {
    return {
      id: this.$route.query.id,
      contractForm: {
        signInfoList: [],
        fileDetailInfoVoApply: null,
        fileDetailInfoVoSeal: null
      },
      showSendEmailPopup: false,
      fileList: [],
      sendEmailForm: {
        sealId: '',
        tos: ''
      }
    }
  },
  computed: {
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.api.contract.detail(this.$route.query.id).then(result => {
        this.contractForm = result.data.value
        this.sendEmailForm.sealId = this.contractForm.sealId
        const fileList = []
        if (this.contractForm.fileDetailInfoVoSeal) {
          fileList.push(this.contractForm.fileDetailInfoVoSeal)
        } else if (this.contractForm.fileDetailInfoVoApply) {
          fileList.push(this.contractForm.fileDetailInfoVoApply)
        }
        this.fileList = fileList
      }).finally(() => {
      })
    },
    sendEmail () {
      if (!this.sendEmailForm.tos) {
        Toast.fail('请输入邮箱地址')
        return false
      }
      this.api.contract.sendEmail(this.sendEmailForm).then(result => {
        Toast.success(result.data.message || '成功')
        this.showSendEmailPopup = false
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
.contract-detail {
  .van-nav-bar{
    position: static;
  }
  .send-email {
    position: fixed;
    bottom: 55px;
    height: 55px;
    width: calc(100% - 20px);
    left: 50%;
    margin-left: calc(-50% + 10px);
    background-color: #FFF;
    z-index: 11;
  }
  .button-area {
    margin-top: 5px;
    margin-bottom: 20px;
    text-align: center;
    >button {
      width: 100px;
      margin: 0 10px;
    }
  }
}

</style>
